<template>
    <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">

        <Alerts :_success="success" :_error="error"></Alerts>

        <div class="preview-title">
            <h4 class="font-weight-semibold">{{ survey.title }}</h4>      
        </div> 

        <div v-if="survey" class="preview">

            <ul class="details-list">

                <!-- Date created -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Date Created</h6>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ moment(survey.created_at).format("MMM D, YYYY HH:mm") }}</span>
                    </div>
                </li>

                <!-- Published -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Published</h6>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ survey.published ? 'Yes' : 'No' }}</span>
                    </div>
                </li>                 

                <!-- Active -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Active</h6>
                        <router-link :to="`/profile/surveys/view/${this.code}/settings`">[Edit]</router-link>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ survey.active ? 'Yes' : 'No' }}</span>
                    </div>
                </li>       

                <!-- Status -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Status</h6>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ survey.status }}</span>
                    </div>
                </li>                 

                <!-- Description -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Description</h6>
                        <router-link :to="`/profile/surveys/create/${this.code}`">[Edit]</router-link>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ survey.description }}</span>
                    </div>
                </li>                

                <!-- Categories -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Categories</h6>
                        <router-link :to="`/profile/surveys/create/${this.code}`">[Edit]</router-link>
                    </div>
                    <div class="details-list-item__desc">
                        <span class="has-multiple" v-for="c in survey.categories" :key="c.id">{{ c.name }}</span>
                    </div>
                </li>           

                <!-- Tags -->
                <li v-if="survey.tags" class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Tags</h6>
                        <router-link :to="`/profile/surveys/create/${this.code}`">[Edit]</router-link>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ survey.tags }}</span>
                    </div>
                </li>           

                <!-- Start date -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Start Date</h6>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ moment(survey.start_date).format("MMM D, YYYY HH:mm") }}</span>
                    </div>
                </li>   

                <!-- End date -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Cut-off Date</h6>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ moment(survey.end_date).format("MMM D, YYYY HH:mm") }}</span>
                    </div>
                </li>                     
                
                <!-- Questions -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Questions</h6>
                        <router-link v-if="!survey.published" :to="`/profile/surveys/view/${this.code}/questionnaire`">[Edit]</router-link>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ survey.questions_count }}</span>
                    </div>
                </li>       

                <!-- Views -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Views</h6>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ survey.views }}</span>
                    </div>
                </li>                               

                <!-- Responses -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Responses</h6>
                        <router-link v-if="survey.published" :to="`/profile/surveys/view/${this.code}/respondents`">[View]</router-link>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ survey.respondents_count }}</span>
                    </div>
                </li>      

                <!-- Share Count -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Share Count</h6>
                    </div>
                    <div class="details-list-item__desc">
                        <span></span>
                    </div>
                </li>                 

                <!-- Show Intro -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Welcome Message</h6>
                        <router-link :to="`/profile/surveys/view/${this.code}/settings`">[Edit]</router-link>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ survey.show_description ? survey.start_message : 'Disabled' }}</span>
                    </div>
                </li>     

                <!-- Show Outro -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>End Message</h6>
                        <router-link :to="`/profile/surveys/view/${this.code}/settings`">[Edit]</router-link>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ survey.end_message ? survey.end_message : 'Disabled' }}</span>
                    </div>
                </li>                          

                <!-- Multiple Responses -->
                <li class="details-list-item">
                    <div class="details-list-item__title">
                        <h6>Allow Multiple Responses</h6>
                        <router-link :to="`/profile/surveys/view/${this.code}/settings`">[Edit]</router-link>
                    </div>
                    <div class="details-list-item__desc">
                        <span>{{ survey.multi_response ? 'Enabled' : 'Disabled' }}</span>
                    </div>
                </li>                                                                                                                                                          

            </ul>
        </div>
    </div>
</template>

<script>
import Alerts from "../../Helpers/Alerts";

export default {
    name: "Details",
    props: ['code'],
    components: {Alerts},
    data() {
        return {
            survey: {}
        }
    },
    mounted() {
        this.$http.get("/api/profile/survey/" + this.code).then(response => {
            this.survey = response.data.data
        }).catch(error => {
            this.showError(error)
        });
    }
}
</script>

<style scoped>

</style>
